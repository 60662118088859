/*eslint-disable*/
export const LANGUAGE = {
  DEFAULT_LANGUAGE: 'en',
  COOKIE_NAME: '#lang',
};

export const LOCATION = {
  GOOGLE_MAP: {
    API_CLIENT: 'map-for-work-ext-02359609', //gme-manpowergroupinc
    API_CHANNEL: '',
    API_KEY: '',
  },
  MESSAGES: {
    INVALID_LOCATION: 'Please enter a valid location',
  },
};

export const API = {
  HOST: '/',
  // HOST: 'https://manpoweruatpoland.manpowergroup.com/',
};

/**
 * @description variable to decide whether to show/hide console logs(need to use consoleLog function from helper utils).
 */
export const showconsole = false;

export const SEARCH = {
  SITE_SEARCH: {
    SEARCH_API: '/api/services/ContentSearch/Search',
  },
  JOB_SEARCH: {
    SAVED_SEARCH_KEY: 'jobSearchFilters',
    SEARCH_API: API.HOST + 'api/services/Jobs/Search',
    SAVE_JOB_API: API.HOST + 'api/services/candidate/savejob',
    JOB_ALERT_API: API.HOST + 'api/services/candidate/createalert',
    JOB_ALERT_API_EMAIL: API.HOST + 'api/services/candidate/CreateAlertByEmail',
    SELECTED_JOB_DETAILS_KEY: 'applyJobDetails',
    SAVED_SEARCH_PARAMS: 'savedSearchParams',
    SEARCH_LOCATION: 'currentSearchLocation',
    SEARCH_API_NEW: API.HOST + 'api/services/Jobs/searchjobs',
    GET_SEARCH_RESULT_SETTINGS:
      API.HOST + 'api/services/Jobs/GetSearchResultsSettings',
  },
};

export const PARTNER_PROJECT = {
  GET_PARTNER_PROJECT_LIST: {
    URL: API.HOST + 'api/services/partnerjobsearch/GetAllJobs',
  },
  POST_PARTNER_PROJECT_FORM: {
    URL: API.HOST + 'api/services/partnerjobsearch/search',
  },
  POST_PARTNER_PROJECT_RELEVANT_JOBS: {
    URL: API.HOST + 'api/services/Jobs/PartnerSearchJobs',
  },
};

export const API_STATUS_CODES = {
  SUCCESS: 1000,
  DATA_EXIST: 1001,
  ERROR: 1002,
  NOT_FOUND: 1003,
  LIMIT_EXCEEDS: 1004,
  OK: 200,
};
export const JOBS_CARD_SCROLL = {
  GET_JOBS_CARD_SCROLL: {
    URL: API.HOST + 'api/services/jobs/GetJobScroll',
  },
  GET_JOBS_CARD_BLOCK: {
    URL: API.HOST + 'api/services/jobs/GetJobCardBlock',
  },
};

export const GET_JOB_MINIMAL_INFO_DETAILS =
  API.HOST + 'api/services/Candidate/GetCandidateMinimalInfo';

export const GET_JOB_DETAILS = API.HOST + 'api/services/RomaCandidate/GetJobDetails';
export const GET_JOB_DETAILS_BY_ID =
  API.HOST + 'api/services/Applicant/GetJobDetails';

export const SCREENER_QUESTIONS = {
  GET_SCREENER_QUESTIONS: API.HOST + 'api/services/jobs/GetQuestions',

  POST_SCREENER_QUESTIONS: API.HOST + 'api/services/jobs/PostAnswers',
};
export const CONSENT_DETAILS = {
  IS_POPUP_REQUIRED: API.HOST + 'api/services/Applicant/IsPopUpRequired',
};

export const CONTACT_US_FORM = {
  POST_URL: API.HOST + 'api/services/forms/contactus',
};

export const EVENT_REGISTRATION_FORM = {
  POST_URL: API.HOST + 'api/services/Event/Register',
};
export const REGISTER_PARTNER = {
  POST_URL: API.HOST + 'api/services/PartnerJobSearch/SubmitInquiry',
};

export const CREATE_PROFILE = {
  POST_CREATE_PROFILE: {
    FILE_ATTACHMENT_UPLOAD: API.HOST + 'api/services/Candidate/UploadAttachment',
    PERSONAL_INFO: API.HOST + 'api/services/Candidate/PostCandidateDetails',
    EDUCATION: API.HOST + 'api/services/Candidate/PostEducation',
    WORK_HISTORY: API.HOST + 'api/services/Candidate/PostWorkHistory',
    SKILL_LIST: API.HOST + 'api/services/Candidate/SkillsList',
    JOB_APPLY: API.HOST + 'api/services/Candidate/JobApply',
    DOWNLOAD_FILE: API.HOST + 'api/services/Candidate/DownloadAttachment',
    PARSE_RESUME: API.HOST + 'api/services/Applicant/ParseCandidateResume',
    POST_CANDIDATEDETAILS_ALL: API.HOST + 'api/services/Candidate/SaveCandidateData',
    JOB_APPLY_NO_AUTH: API.HOST + 'api/services/Candidate/JobApplyNoAuth',
    POST_CANDIDATEDETAILS: API.HOST + 'api/services/Candidate/SaveCandidateData',
    JOB_APPLY_AUTH: API.HOST + 'api/services/Applicant/JobApplyWithToken',
    JOB_APPLY_WITH_EMAIL: API.HOST + 'api/services/Applicant/JobApplyWithEmail',
    JOB_APPLY_WITHDRAW: API.HOST + 'api/services/applicant/withdrawjob',
  },
  GET_CREATE_PROFILE: {
    CANDIDATE_DETAILS: API.HOST + 'api/services/applicant/GetApplicantDetails',
  },
};

export const DASHBOARD = {
  POST_DASHBOARD: {
    PROFILE_PICTURE: API.HOST + 'api/services/Candidate/UpdateProfileImage',
  },
  GET_DASHBOARD: {
    PROFILE_PICTURE: API.HOST + 'api/services/Candidate/GetProfileImage',
  },
  SUGGESTED_JOBS: {
    API: API.HOST + 'api/services/jobs/GetJobScroll',
    JOBS_API: API.HOST + 'api/services/jobs/GetJobCardBlock',
    PAGE_TYPE: 'Dashboard',
  },
  SAVED_JOBS: {
    GET: API.HOST + 'api/services/Candidate/GetSavedJobs',
    DELETE: API.HOST + 'api/services/Candidate/DeleteJob',
  },
};

export const ACCOUNT_SETTINGS = {
  COMMUNICATION_PREFERENCES: {
    GET_ALERT_API: API.HOST + 'api/services/candidate/GetAlerts',
    UPDATE_ALERT_API: API.HOST + 'api/services/candidate/UpdateAlerts',
    DELETE_ALERT_API: API.HOST + 'api/services/candidate/DeleteJobAlert',
    UPDATE_JOB_ALERT_API: API.HOST + 'api/services/candidate/UpdateJobAlert',
  },
  CONSENT: {
    UPDATE_CONSENT: API.HOST + 'api/services/Applicant/SaveCandidateConsent',
  },
};

export const UPDATE_SESSION = API.HOST + 'api/services/candidate/UpdateSession';

export const STRING_VALUES = {
  YES: 'Yes',
  NO: 'No',
  SUCCESS: 'SUCCESS',
  MANPOWER: 'manpower',
};

export const ROMA_INTEGRATION = {
  ROMA_AUTHENTICATE: API.HOST + 'api/services/Romacandidate/AuthenticateUser',
};

export const LOGIN = {
  FACEBOOK: '',
  LINKEDIN: 'https://www.linkedin.com/oauth/v2/authorization',
  LINKEDINACCESSTOKEN: 'https://www.linkedin.com/oauth/v2/accessToken',
  LINKEDINUSER: 'https://api.linkedin.com/v2/me',
  LINKEDIN_REDIRECTTOSIGNIN: '/sign-in',
  LINKEDIN_REDIRECTTOSIGNUP: '/create-account',
  LINKEDIN_CONSENT: 'userconsent',
  POSITION_ID: 'PositionID',
  SOURCE: 'Source',
};

export const RENEW_ALERT = {
  RENEW: API.HOST + 'api/services/Candidate/RenewAlerts',
};

export const GTM = {
  EVENTS: {
    JOB_DETAILS: 'vacancyPageload',
    JOB_APPLICATION: 'applicationFormPageload',
    JOB_APPLICATION_SUCCESS: 'purchase',
    JOB_SEARCH_RESULTS: 'searchResultPageload',
    SEO_JOB_SEARCH_RESULTS: 'vacanciesListPageLoad',
    SEARCH_RESULT_DETAILS: 'searchResultClick',
  },
};
export const BLOG_INSIGHT_CARDBLOCK = {
  POST_URL: API.HOST + 'api/services/article/GetArticles',
  POST_FILTER_URL: API.HOST + 'api/services/article/GetArticleFilters',
};

export const STORAGE = {
  UUID: 'uuid',
  PAGE_EVENT: 'disablePageLoadEvent',
};

export const DEVICE = {
  MOBILE: {
    WIDTH: 768,
  },
  TABLET: {
    WIDTH: 992,
  },
};

export const DELETE_ACCOUNT = {
  DELETE_API: API.HOST + 'api/services/candidate/DeleteMyAccount',
  DELETE_ACC_API: API.HOST + 'api/services/applicant/DeleteAccount',
};

export const SOCIAL_LOGIN_VALUES = new Map([
  ['local', 'Email'],
  ['facebook.com', 'Facebook'],
  ['google.com', 'Google'],
]);

export const GET_ITEM_DETAILS =
  API.HOST + 'api/services/Component/GetComponentSetting';
