import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import SiteFooterNavManpower from '../SiteFooter/SiteFooterNavManpower';
import './sitefooter.scss';
import '../globals/link/links.scss';
import './sitefootercountryselector.scss';

/**
 * @description - Site Footer Container.
 * @param {Object} props - Input props.
 * @returns {Node} - Footer element.
 */
const ManpowerFooterContainer = (props) => {
  const {
    tabComponents = [],
    disclaimerComponent = [],
    appsComponent = [],
    sitecoreContext,
    countryLinksComponent = [],
  } = props;
  const hasAppButtons = appsComponent?.length;
  const placeholders = sitecoreContext?.route?.placeholders;
  const common = placeholders && placeholders['jss-common'];
  const { items = [] } = (common && common[0]?.fields) || {};
  const itemsArr = [];
  items.map((obj) => {
    obj.fields && obj.fields.items && itemsArr.push(...obj.fields.items);
  });
  const [footerContent, setFooterContent] = useState([]);
  const country_code = sitecoreContext?.Country?.code?.toLowerCase();

  useEffect(() => {
    setFooterContent(disclaimerComponent[0]);
  }, [disclaimerComponent]);

  return (
    <footer className={'site-footer manpower'}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <SiteFooterNavManpower menuItems={itemsArr} />
            {countryLinksComponent.map((component, index) => {
              if (component.props && component.props.type === 'text/sitecore')
                return component;
              return <Fragment key={index}>{component}</Fragment>;
            })}
          </div>
          <div className="col-lg-4">
            <div className="footer-rightside">
              {tabComponents.map((component, index) => {
                if (component.props && component.props.type === 'text/sitecore')
                  return component;
                return <Fragment key={index}>{component}</Fragment>;
              })}
            </div>
          </div>
        </div>
      </div>
      {/* This is being used to display footer disclaimer content */}
      {footerContent?.props && footerContent?.props?.type === 'text/sitecore' ? (
        footerContent
      ) : (
        <Fragment key="content">{footerContent}</Fragment>
      )}
      {/* This loop is only used to display blank placeholder in experience editor for footer disclaimer */}
      {disclaimerComponent.map((component) => {
        if (component?.props && component?.props?.type === 'text/sitecore')
          return component;
      })}
      {/* This is being used to display footer bottom content (copyrights, social Icons and Counry selector) */}
      {!isExperienceEditorActive() && (
        <div
          className={`sub-footer-disclaimer ${
            country_code === 'de' ? 'de-site-manpower' : ''
          }`}
        >
          <div
            className={`sub-footer-disclaimer-inner col-10 ${
              hasAppButtons ? 'apps-btn' : ''
            }`}
          >
            {disclaimerComponent.map((component, index) => {
              if (index > 0) {
                if (component?.props && component?.props?.type === 'text/sitecore')
                  return component;
                return <Fragment key={index}>{component}</Fragment>;
              }
            })}
          </div>
        </div>
      )}
      <div className="sub-footer-apps">
        <div className="row applinks col-10">
          {/* This loop is only used to display blank placeholder in experience editor for footer apps component */}
          {appsComponent.map((component) => {
            if (component?.props && component?.props?.type === 'text/sitecore')
              return component;
          })}
          {/* This is being used to display footer bottom content (Apple / Android icons) */}
          {!isExperienceEditorActive() && (
            <>
              {appsComponent.map((component) => {
                if (component?.props && component?.props?.type === 'text/sitecore')
                  return component;
                return component;
              })}
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

ManpowerFooterContainer.defaultProps = {
  tabComponents: [],
  disclaimerComponent: [],
  appsComponent: [],
  countryLinksComponent: [],
  fields: {},
  sitecoreContext: {},
};

ManpowerFooterContainer.propTypes = {
  tabComponents: PropTypes.arrayOf(PropTypes.shape()),
  disclaimerComponent: PropTypes.arrayOf(PropTypes.shape({})),
  appsComponent: PropTypes.arrayOf(PropTypes.shape({})),
  countryLinksComponent: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

const containerComp = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-footer-right', prop: 'tabComponents' },
    { placeholder: 'jss-footer-disclaimer', prop: 'disclaimerComponent' },
    { placeholder: 'jss-right', prop: 'countryLinksComponent' },
    { placeholder: 'jss-footer-app', prop: 'appsComponent' },
  ])(ManpowerFooterContainer)
);

export default containerComp;
